import React from 'react'
import "./index.scss"
import { Divider } from 'antd'

export const LeftSideMenu = () => {
    return (
        <div>
            <div id="mySidenav" class="sidenav">
                <a href="/privacy-policy">Privacy Policy</a>
                <Divider />
                <a href="/disclaimer">Disclaimer</a>
            </div>
        </div>
    )
}
