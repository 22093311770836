import { Link } from "react-router-dom";
import "./index.scss";
import Countries from "./Countries";
import { CommonContext } from "../../../context/CommonContext";
import { useContext, useEffect } from "react";
import Industries from "./Industries";

const Rankings = () => {
  const { isActive, setActive, setSearchData, setShowedSearchTerm } =
    useContext(CommonContext);

useEffect(() => {
  setShowedSearchTerm("")
  setSearchData("")
}, [])


  return (
    <>
      <div className="menu">
        <div className="menu-left">
          <div className="rankings-link">
            <Link
              to="/"
              onClick={(e) => {
                setActive(!isActive);
                setSearchData("");
                setShowedSearchTerm("");
              }}
            >
              Global Ranking
            </Link>
          </div>
          <div className="rankings-link">
            <Countries />
          </div>
          <div className="rankings-link">
            <Industries />
          </div>
        </div>
        <div className="menu-right">
          <Link
            to="/about"
            onClick={() => {
              setActive(!isActive);
              setShowedSearchTerm("");
              setSearchData("");
            }}
          >
            About
          </Link>
          <Link
            to="/contact"
            onClick={() => {
              setActive(!isActive);
              setShowedSearchTerm("");
              setSearchData("");
            }}
          >
            Contact
          </Link>
        </div>
      </div>
    </>
  );
};

export default Rankings;
