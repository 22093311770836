import Rankings from "../Rankings";
import ColumnGraphicThree from "../Details/ColumnGraphicThree";
import ColumnGraphicTwo from "../Details/ColumngraphicTwo";
import ColumnGraphicOne from "../Details/ColunmGraphicOne";
import CompanyInfo from "../Details/CompanyInfo";
import PieGraphicOne from "../Details/PieGraphicOne";
import PieGraphicTwo from "../Details/PieGraphicTwo";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GetAllCompaniesDetails } from "../../../services/allservices";
import { CommonContext } from "../../../context/CommonContext";
import Footer from "../../Footer";

const Details = () => {
    const { setCompanyDetail, setSearchData } = useContext(CommonContext);
    const params = useParams();
    const [canRender, setCanRender] = useState(false);
    let num = params.id;
    
    useEffect(() => {
        getCompanyDetail(num);
        setSearchData("");
        window.scrollTo(0, 0, { behavior: 'smooth' });
    }, []);

    const getCompanyDetail = async (num) => {
        const response = await GetAllCompaniesDetails(num);
        if (response) {
            setCompanyDetail(() => (response.data));
            setCanRender(true);
        }
    };
    
    
    if(canRender) {
        return(
            <>
            <Rankings />
            <div className="detail-container">
                <div className="details">
                    <CompanyInfo />
                </div>
                {/* <div className="detail-frame">
                    <PieGraphicOne />
                </div> */}
                <div className="detail-frame">
                    <PieGraphicTwo />
                </div>
                <div className="detail-frame">
                    <ColumnGraphicOne />
                </div>
                <div className="detail-frame">
                    <ColumnGraphicTwo />
                </div>
                <div className="detail-frame">
                    <ColumnGraphicThree />
                </div>
            </div>
        </>
        )
    } else {
        return(
            <div className="not-found-page"></div>
        )
    }
}

export default Details;