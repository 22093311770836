import { Column } from '@ant-design/plots';
import { useContext } from 'react';
import { CommonContext } from '../../../context/CommonContext';
import "../index.scss";


const ColumnGraphicThree = () => {
  const { companyDetail } = useContext(CommonContext);
  const data = [];
  let years= "";

  Object.keys(companyDetail.employeeSpentTimeDto.employeeSpentTimeDetailDto).map((key, index)=> {
    years = index == 0 ? "0 - 2" : index == 1 ? "2 - 4" : index == 2 ? "4 - 6" : index == 3 ? "6 - 8" : index == 4 ? "8-10" : "10+";
    data.push({
      "employee": `${years}`,
      "time": Number(companyDetail.employeeSpentTimeDto.employeeSpentTimeDetailDto[key].toFixed(1))
    });
  });

  const config = {
    data,
    xField: 'employee',
    yField: 'time',
    label: {
      position: 'middle',
      style: {
        fill: '#ffffff',
        opacity: 0.6,
        fontSize: 16,
      },
    },

    columnStyle: {
      fill: `#02283B`,
      stroke: '#02283B',
      lineWidth: 1,
      radius: [16, 16, 0, 0],
    },
    tooltip: false,
  };
  return (
    <>
      <div>
        <span className='container-title'>Time Spend at the Company</span>
        <div className='graphic'>
          <div className='graphic-left'>
            <Column renderer='svg' {...config} xAxis={{title: {text: 'Years'}}} yAxis={{
              title: { text: 'Percentage'}, label: {
                formatter: (value) => `${value}%`,
              },
            }} />
          </div>
          <div className='graphic-right'>
            <span>Avg. Employee Tenure (years)</span>
            <span className='bold'>{Number(companyDetail.employeeSpentTimeDto.avgSpentTime).toFixed(1)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColumnGraphicThree;