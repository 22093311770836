import './App.scss';
import { CommonProvider } from './context/CommonContext';
import Layout from "./Layout";
import Footer from './modules/Footer';

function App() {
  return (
      <div className="App">
        <CommonProvider>
        <Layout />
        <Footer />
        </CommonProvider>
      </div>
  );
}

export default App;
