import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Spin, Table, List } from "antd";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import linkedinLogo from "../../assets/svg/icons8-linkedin.svg";

Chart.register(CategoryScale);

const TestPDF = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [requestTime, setRequestTime] = useState(null);
  const [resume, setResume] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseCode, setResponseCode] = useState(null);
  const [error, setError] = useState(null);
  const [salary, setSalary] = useState(null);
  const [isUsedBefore, setIsUsedBefore] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    setIsUsedBefore(true);
    setSalary(null);
    setRequestTime(null);
    setError(false);
    setResume([]);
    setIsDisabled(true);
    const BASE_URL = "https://app.talentscore.io/server/api/v1";
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const startTime = new Date();
      setLoading(true);
      try {
        const response = await axios.post(BASE_URL + "/pdf/cv", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwYXlzaWdodEBnaW5pdGFsZW50LmNvbSIsInN1YnNjcmlwdGlvbnMiOlsiRnJlZSJdLCJzdXJuYW1lIjoiQWRtaW4iLCJyb2xlcyI6W10sIm5hbWUiOiJTdXBlciIsImV4cCI6MTY5OTcwNTI2MH0.H3h67fzm6mbwI9j8MilBvF-JcfnGIuAAXQLHoSZe1cHbv4I_tv6gswM8BLoynCON-MKf9MfeGTqnZI_3uUhigQ",
          },
        });

        const endTime = new Date();
        const requestDuration = (endTime - startTime) / 1000;
        setRequestTime(requestDuration);

        setResume(response?.data?.data?.cv);
        setResponseCode(response?.data?.code);

        if (response?.data?.data?.cv) {
          const resumeId = response.data.data.cv.id;
          try {
            const response = await axios.get(
              BASE_URL + "/salary/predict/" + resumeId,
              {
                headers: {
                  Authorization:
                    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwYXlzaWdodEBnaW5pdGFsZW50LmNvbSIsInN1YnNjcmlwdGlvbnMiOlsiRnJlZSJdLCJzdXJuYW1lIjoiQWRtaW4iLCJyb2xlcyI6W10sIm5hbWUiOiJTdXBlciIsImV4cCI6MTY5OTcwNTI2MH0.H3h67fzm6mbwI9j8MilBvF-JcfnGIuAAXQLHoSZe1cHbv4I_tv6gswM8BLoynCON-MKf9MfeGTqnZI_3uUhigQ",
                },
              }
            );
            setSalary(response?.data);
            console.log(response);
          } catch (error) {
            setError(true);
          }
          setLoading(false);
        } else {
          console.error("resume.id is undefined.");
        }
      } catch (error) {
        setLoading(false);
        setError(true);
      } finally {
        setLoading(false);
        setIsDisabled(false);
        setSelectedFile(null);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const columns = [
    {
      title: "Supervision Coefficient",
      dataIndex: "supervisionCoef",
      key: "supervisionCoef",
    },
    {
      title: "Education Coefficient",
      dataIndex: "eduCoef",
      key: "eduCoef",
    },
    {
      title: "Company Coefficient",
      dataIndex: "lastCompCoef",
      key: "lastCompCoef",
    },
    {
      title: "Country Coefficient",
      dataIndex: "countryCoef",
      key: "countryCoef",
    },
    {
      title: "Salary Coefficient",
      dataIndex: "salaryCoef",
      key: "salaryCoef",
    },
  ];

  const salaryColumn = [
    {
      title: "Min Salary",
      dataIndex: "predictedMinSalary",
      key: "predictedMinSalary",
    },
    {
      title: "Median Salary",
      dataIndex: "salaryMedian",
      key: "salaryMedian",
    },
    {
      title: "Max Salary",
      dataIndex: "predictedMaxSalary",
      key: "predictedMaxSalary",
    },
  ];

  const topicScoreColumn = [
    {
      title: "Topic",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
  ];

  const salaryData = [
    {
      key: "1",
      predictedMinSalary: salary?.predictedMinSalary,
      salaryMedian: salary?.salaryMedian,
      predictedMaxSalary: salary?.predictedMaxSalary,
    },
  ];

  const data = [
    {
      key: "1",
      supervisionCoef: salary?.supervisionCoef,
      eduCoef: salary?.eduCoef,
      lastCompCoef: salary?.lastCompCoef,
      countryCoef: salary?.countryCoef,
      salaryCoef: salary?.salaryCoef,
    },
  ];

  const options = {
    scales: {
      y: {
        min: 0,
        max: 100,
        stepSize: 20,
      },
    },
    responsive: true,
    plugins: {
      legend: null,
      title: {
        display: true,
        text: "Salary Range Percentages",
      },
    },
  };

  const BarChart = ({ data }) => {
    return <Bar data={data} options={options} />;
  };

  const barChartData = {
    labels: salary?.salaryIntervalsYearly,
    datasets: [
      {
        label: "Salary Range Percentages",
        backgroundColor: "rgba(2, 40, 59,1)",
        borderColor: "rgba(2, 40, 59,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(2, 40, 59,0.6)",
        hoverBorderColor: "rgba(2, 40, 59,1)",
        data: [
          salary?.salaryIntervalsPercentages?.colOne,
          salary?.salaryIntervalsPercentages?.colTwo,
          salary?.salaryIntervalsPercentages?.colThree,
          salary?.salaryIntervalsPercentages?.colFour,
          salary?.salaryIntervalsPercentages?.colFive,
          salary?.salaryIntervalsPercentages?.colSix,
          salary?.salaryIntervalsPercentages?.colSeven,
          salary?.salaryIntervalsPercentages?.colEight,
        ],
      },
    ],
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="width-responsive" style={{ marginBlock: 50 }}>
        <div {...getRootProps()} style={dropzoneStyles}>
          <input {...getInputProps()} />
          <p className="m-4 ts-font-regular-14">
            Choose a PDF or drag it here.
          </p>
        </div>
        {selectedFile && (
          <div>
            <p>File: {selectedFile.name}</p>
            <div className="d-flex justify-content-center">
              <button
                style={tsButton}
                onClick={handleUpload}
                disabled={isDisabled}
              >
                Upload File
              </button>
            </div>
          </div>
        )}
        {requestTime && (
          <div>
            <p>Request Time: {requestTime} sn</p>
          </div>
        )}
        {isUsedBefore &&
          (loading ? (
            <div style={loadingStyle}>
              <Spin></Spin>
            </div>
          ) : responseCode === 200 && !error ? (
            <div className="mt-5">
              <h1>{resume?.fullName}</h1>
              <h6>{resume?.title}</h6>
              <p>{resume?.email}</p>
              {resume?.linkedinUrl && (
                <a
                  className="url ts-font-regular-16"
                  target="_blank"
                  rel="noreferrer"
                  href={"https://www.linkedin.com/in/" + resume.linkedinUrl}
                >
                  <img src={linkedinLogo} alt="Linkedin Logo"></img>
                </a>
              )}
              <p>{resume?.foundCountry}</p>

              {resume?.summary && (
                <>
                  <h5 className="mt-5 ts-font-bold-20">SUMMARY</h5>
                  <p className="ts-font-regular-14">{resume.summary}</p>
                </>
              )}

              {resume?.experiences?.length > 0 && (
                <h5 className="mt-5 ts-font-bold-20">EXPERIENCE</h5>
              )}
              {resume?.experiences?.map((experience, index) => (
                <div style={section} key={index} className="ts-font-regular-14">
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>
                        {experience?.company?.name ? (
                          <a
                            href={
                              "https://www.linkedin.com/company/" +
                                experience?.company?.linkedinNumericRef ??
                              experience?.company?.linkedinRef
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {experience?.company.name}
                          </a>
                        ) : (
                          experience?.unknownCompanyName
                        )}
                      </b>
                    </p>
                    {experience?.company?.logo ? (
                      <img
                        src={`data:image/png;base64,${experience?.company?.logo}`}
                        alt="company logo"
                        width={70}
                        height={70}
                      />
                    ) : null}
                  </div>
                  {experience?.company?.score && (
                    <p>
                      <b>Score:</b> {experience?.company?.score}
                    </p>
                  )}
                  {experience?.period && experience?.duration && (
                    <p>
                      <b>Period:</b> {experience?.period} (
                      {experience?.duration})
                    </p>
                  )}
                  {experience?.position && (
                    <p>
                      <b>Title:</b> {experience?.position}
                    </p>
                  )}
                  {experience?.place && (
                    <p>
                      <b>Location:</b> {experience?.place}
                    </p>
                  )}
                  {experience?.description && (
                    <p>
                      <b>Description:</b> {experience?.description}
                    </p>
                  )}
                </div>
              ))}
              {resume?.educations?.length > 0 && (
                <h5 className="mt-5 ts-font-bold-20">EDUCATION</h5>
              )}
              {resume?.educations?.map((education, index) => (
                <div style={section} key={index} className="ts-font-regular-14">
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>
                        {education?.school?.name ? (
                          <a
                            href={
                              "https://www.linkedin.com/school/" +
                                education?.school?.linkedinSecondaryRef ??
                              education?.school?.linkedinRef
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {education?.school.name}
                          </a>
                        ) : (
                          education?.unknownSchoolName
                        )}
                      </b>
                    </p>
                    {education?.school?.logo ? (
                      <img
                        src={`data:image/png;base64,${education?.school?.logo}`}
                        alt="company logo"
                        width={70}
                        height={70}
                      />
                    ) : null}
                  </div>
                  {education?.school?.score && (
                    <p>
                      <b>Score:</b> {education?.school?.score}
                    </p>
                  )}
                  {education?.period && (
                    <p>
                      <b>Period:</b> {education?.period}
                    </p>
                  )}
                  {education?.degree && (
                    <p>
                      <b>Program:</b> {education?.degree}
                    </p>
                  )}
                  {education?.degreeType && (
                    <p>
                      <b>Degree:</b> {education?.degreeType}
                    </p>
                  )}
                  {education?.description && (
                    <p>
                      <b>Description:</b> {education?.description}
                    </p>
                  )}
                </div>
              ))}
              {resume?.cvMainSkillDto?.length > 0 && (
                <h5 className="mt-5 ts-font-bold-20">SKILLS</h5>
              )}
              {resume?.cvMainSkillDto?.map((skill, index) => (
                <div key={index} className="ts-font-regular-14">
                  <p>{skill?.name}</p>
                </div>
              ))}
              {resume?.certifications?.length > 0 && (
                <h5 className="mt-5 ts-font-bold-20">CERTIFICATIONS</h5>
              )}
              {resume?.certifications?.map((certification, index) => (
                <div key={index} className="ts-font-regular-14">
                  <p>{certification?.name}</p>
                </div>
              ))}
              {salary && (
                <>
                  <h5 className="mt-5 ts-font-bold-20">SALARY</h5>
                  <div className="ts-font-regular-14">
                    <p>
                      <b>Assigned Topic No :</b> {salary?.assignedTopicNo}
                    </p>
                    {/* <p><b>Assigned Topic Word :</b> {salary?.allTopicWords}</p>
                                    <p><b>Topic Score :</b> {salary?.allTopicScores}</p> */}
                    <p>
                      <b>Skills :</b> {salary?.skills}
                    </p>
                    <p>
                      <b>Main Skill :</b> {salary?.mainSkills}
                    </p>
                    <p className="m-0">
                      <b>Working Year :</b> {salary?.workingYear}
                    </p>
                    {salary?.allTopicWords && (
                      <List
                        className="mt-4"
                        itemLayout="horizontal"
                        dataSource={salary?.allTopicWords}
                        renderItem={(item) => (
                          <List.Item style={{ padding: "15px 0" }}>
                            <List.Item.Meta
                              title={item?.name}
                              description={item?.featuredWords}
                            />
                          </List.Item>
                        )}
                      />
                    )}
                    {salary?.allTopicScores && (
                      <Table
                        className="mt-5"
                        columns={topicScoreColumn}
                        dataSource={salary?.allTopicScores}
                        pagination={false}
                      />
                    )}
                    {salary?.predictedMinSalary && (
                      <Table
                        className="mt-5"
                        columns={salaryColumn}
                        dataSource={salaryData}
                        pagination={false}
                      />
                    )}
                    {salary?.eduCoef && (
                      <Table
                        className="mt-5 mb-5"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                      />
                    )}
                  </div>
                  {salary?.salaryIntervalsPercentages && (
                    <BarChart className="mt-5" data={barChartData} />
                  )}
                </>
              )}
            </div>
          ) : responseCode === 996 && error ? (
            <div className="d-flex justify-content-center">
              <p>
                This file has already been uploaded. Please try with a different
                file.
              </p>
            </div>
          ) : responseCode === 999 ? (
            <div className="d-flex justify-content-center">
              <p>We couldn't find an email in this document.</p>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <p>Something went wrong!</p>
            </div>
          ))}
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  textAlign: "center",
  cursor: "pointer",
  padding: "20px",
};

const section = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  margin: "20px 0",
  padding: "20px",
};

const tsButton = {
  backgroundColor: "#02283b",
  color: "#fff",
  borderRadius: "4px",
  textAlign: "center",
  cursor: "pointer",
  padding: "10px",
};
const loadingStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "60vh",
};

export default TestPDF;
