const Utils = {
  getHostNameOfUrl: (url) => {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === "string" &&
      match[2].length > 0
    ) {
      return match[2];
    }
    return null;
  },
  companySizeChecker: (text) => {
    return text !== undefined && text !== null && text !== "NOT_FOUND"
      ? text.includes("K")
        ? text
        : text.includes("-")
        ? (() => {
            let parts = text.split("-");
            return (
              Number(parts[0]).toLocaleString() +
              "-" +
              Number(parts[1]).toLocaleString()
            );
          })()
        : text.includes("+")
        ? (() => {
            let part = text.split("+");
            return Number(part[0]).toLocaleString() + "+";
          })().toLocaleString()
        : Number(text).toLocaleString()
      : "N/A";
  },
  scoreColorChecker: (score) => {
    if (score <= 4) {
      return "#FF8267";
    } else if (score > 4 && score <= 7) {
      return "#FFC700";
    } else if (score > 7) {
      return "#28C76F";
    } else {
      return "gray";
    }
  },
};

export default Utils;
