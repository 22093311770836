import { Column } from '@ant-design/plots';
import { useContext } from 'react';
import { CommonContext } from '../../../context/CommonContext';
import "../index.scss";


const ColumnGraphicTwo = () => {
  const { companyDetail } = useContext(CommonContext);
  const data = [];
  let years = "";

  Object.keys(companyDetail.employeeSeniorityDto.employeeSeniorityDetailDtos).map((key, index) => {
    years = index == 0 ? "0 - 4" : index == 1 ? "4 - 8" : index == 2 ? "8 - 12" : index == 3 ? "12 - 16" : index == 4 ? "16-20" : "20+";
    data.push({
      "employee": `${years}`,
      "percent": Number(companyDetail.employeeSeniorityDto.employeeSeniorityDetailDtos[key].toFixed(1))
    });
  });

  const config = {
    data,
    xField: 'employee',
    yField: 'percent',
    label: {
      position: 'middle',
      style: {
        fill: '#ffffff',
        opacity: 0.6,
        fontSize: 16,
      },
    },

    columnStyle: {
      fill: `#02283B`,
      stroke: '#02283B',
      lineWidth: 1,
      radius: [16, 16, 0, 0],
    },
    tooltip: false,
  };
  return (
    <>
      <div>
        <span className='container-title'>Employee Seniority</span>
        <div className='graphic'>
          <div className='graphic-left'>
            <Column renderer='svg' {...config} xAxis={{ title: { text: 'Years' } }} yAxis={{
              title: { text: 'Percentage' }, label: {
                formatter: (value) => `${value}%`,
              },
            }} />
          </div>
          <div className='graphic-right'>
            <span>Avg. employee experience (years)</span>
            <span className='bold'>{Number(companyDetail.employeeSeniorityDto.avgYear).toFixed(1)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColumnGraphicTwo;