import { Layout } from "antd";
import "./index.scss";
import AppLogo from "../assets/images/app-logo-w.png";
import Companies from "../modules/Companies";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import About from "../modules/About";
import Contact from "../modules/Contact";
import Details from "../modules/Companies/Details/index";
import ResumeSearch from "../modules/ResumeSearch";
import Hamburger from "../Layout/Hamburger";
import { useContext } from "react";
import { CommonContext } from "../context/CommonContext";
import GetListed from "../modules/GetListed";
import { Disclaimer } from "../modules/Contracts/Disclaimer";
import { PrivacyPolicy } from "../modules/Contracts/Privacy";
import TestPDF from "../modules/TestPDF";
import psLogo from "../assets/svg/ps-logo.svg";

const { Header, Content } = Layout;

const MasterLayout = () => {
  const { isActive, setActive } = useContext(CommonContext);
  const location = useLocation();
  const path = location?.pathname;
  const isPaysight = path === "/resume-search" || path === "/pdf-test";
  const headerStyle = isPaysight
    ? {
        backgroundColor: "white",
        borderBottom: "1px solid lightgray",
        boxShadow: "rgba(0, 0, 0, 0.1) 0 0px 20px 5px",
      }
    : {};

  return (
    <>
      <Layout className="layout">
        <Header className="site-header" style={headerStyle}>
          <div
            className="hamburger-menu"
            onClick={() => {
              setActive(!isActive);
            }}
          >
            <Hamburger />
          </div>
          <div className={isPaysight ? "" : "logo"}>
            <Link to="/">
              <img src={isPaysight ? psLogo : AppLogo} alt="Gini Talent" />
            </Link>
          </div>
        </Header>

        <Content>
          <div className={isActive ? "site-layout" : "site-layout active"}>
            <Routes>
              <Route path="/*" element={<Companies />} />
              <Route path="/detail/:id" element={<Details />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/getListed" element={<GetListed />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/pdf-test" element={<TestPDF />} />
              <Route path="/resume-search" element={<ResumeSearch />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default MasterLayout;
