import "./index.scss";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useContext, useEffect } from "react";
import { CommonContext } from "../../context/CommonContext";
import Rankings from "../Companies/Rankings";
import GiniTalentLogo from "../../assets/images/gini-talent_logo.svg";
import axios from 'axios';
import { BASE_URL, TOKEN } from "../../helpers/constants";
import { message } from "antd";

const GetListed = () => {
    const { setSearchData, mobileMatched } = useContext(CommonContext);

    useEffect(() => {
        setSearchData("");
    }, [setSearchData]);

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Too Short!')
            .required('This field cannot be left blank.'),
        companyName: Yup.string()
            .required('This field cannot be left blank.'),
        companyEmail: Yup.string()
            .email('Invalid email address.')
            .required('This field cannot be left blank.'),
        mobileNumber: Yup.string()
            .matches(/^\d+$/, "Mobile number can only contain digits")
            .test('len', 'Mobile number needs to be between 11 and 15 digits', val => val.length >= 10 && val.length <= 15)
            .required('This field cannot be left blank.'),
    });

    const initialValues = {
        name: '',
        companyName: '',
        companyEmail: '',
        mobileNumber: '',
    };

    const onSubmit = async (values) => {

        const headers = {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
        };

        const response = await axios.post(BASE_URL + 'get-listed', {
            name: values.name,
            companyName: values.companyName,
            email: values.companyEmail,
            mobileNumber: values.mobileNumber.toString(),
        }, {
            headers: headers,
        });

        if (response.data.code === 200) {
            message.success({
                type: 'success',
                content: 'Thank you. We will be in touch shortly. Redirecting to home page in 5 seconds.',
                duration: 5,
            }).then(() => {
                window.location.href = "/";
            });
        } else {
            message.error({
                type: 'error',
                content: 'There was an error sending your message. Please try again',
                duration: 5,
            });
        }

    };

    return (
        <>
            <Rankings />
            <div className="contact-container">
                <div className="form-container">
                    <span className="title ts-font-bold">Get<span className="red-title ts-font-bold"> Listed</span></span>
                    <span className="text ts-font-medium">Let us know your company details</span>
                    <span className="text-opacity ts-font-light">We will get back in 2 business days</span>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={SignupSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValid }) => (
                                <Form>
                                    <Field className="ts-font-regular" id="name" name="name" placeholder="Name" />
                                    {errors.name && touched.name ? (<div className="text-danger error-message">{errors.name}</div>) : null}
                                    <Field className="ts-font-regular" id="companyName" name="companyName" placeholder="Company Name" />
                                    {errors.companyName && touched.companyName ? (<div className="text-danger error-message">{errors.companyName}</div>) : null}
                                    <Field
                                        className="ts-font-regular"
                                        id="companyEmail"
                                        name="companyEmail"
                                        placeholder="Company E-Mail"
                                        type="email"
                                    />
                                    {errors.companyEmail && touched.companyEmail ? (<div className="text-danger error-message">{errors.companyEmail}</div>) : null}
                                    <Field className="ts-font-regular" id="mobileNumber" type="number" name="mobileNumber" placeholder="Mobile Number" />
                                    {errors.mobileNumber && touched.mobileNumber ? (<div className="text-danger error-message">{errors.mobileNumber}</div>) : null}
                                    <button type="submit" className="mt-4 ts-font-regular" disabled={!isValid}>Submit</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="map-container">
                    <div className="d-flex align-items-center" style={{ fontWeight: '700' }}>We are <a href="https://ginitalent.com/" target="_blank" rel="noreferrer"><img alt="talentscore" className="w-50 ms-3" src={GiniTalentLogo}></img></a></div>
                    <span className="mt-3 text ts-font-regular">A technology focused recruitment company serving to the global brands.</span>
                    <span className="ts-font-regular"><a href="https://ginitalent.com/" target="_blank" rel="noreferrer" >Click here</a> to learn more about us.</span>
                </div>
            </div>
        </>
    )
}

export default GetListed;