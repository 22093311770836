import "./index.scss"
import { useContext, useEffect } from "react";
import { CommonContext } from "../../context/CommonContext";
import Rankings from "../Companies/Rankings";


const About = () => {
    const { setSearchData } = useContext(CommonContext);

    useEffect(() => {
        setSearchData("");
    }, []);

    return (
        <>
            <Rankings />
            <div className="about-container">
                <div className="big-title-center">
                    <span>talentscore</span>
                    <span>To Discover the Best Talents with a Sharp Eye</span>
                </div>
                <div className="ts-font-regular-16 ts-color-primary" style={{ padding: '0 15% 6% 15%' }}>
                    <div className="d-flex">
                        <span style={{ fontSize: '26px', marginRight: '10px' }}>🎯</span>
                        talentscore is an advanced evaluation tool that not only assesses companies but also individuals based on their academic background, educational institutions attended, and professional experiences. This powerful platform leverages a sophisticated algorithm to quantify and rank the talent of individuals, providing valuable insights into their expertise and career trajectory.
                    </div>
                    <br /><br />
                    <div className="d-flex">
                        <span style={{ fontSize: '26px', marginRight: '10px' }}>🎓</span>
                        One of the key components of talentscore's individual assessment is the academic background of individuals. By considering the educational institutions they attended, including top-tier schools and renowned universities, the tool evaluates the quality and depth of their knowledge. This analysis helps identify individuals who have received a rigorous education and possess a strong foundation in their respective fields.                    </div>
                    <br /><br />
                    <div className="d-flex">
                        <span style={{ fontSize: '26px', marginRight: '10px' }}>🏢</span>
                        In addition to academic background, talentscore takes into account an individual's work history and the companies they have been associated with. By assessing the reputation and influence of the organizations where individuals have worked, the tool gauges the level of professional experience and exposure they have gained. This evaluation enables talentscore to recognize individuals who have excelled in their careers, demonstrated leadership qualities, and contributed significantly to their previous employers' success.                    </div>                    <br /><br />
                    <div className="d-flex">
                        <span style={{ fontSize: '26px', marginRight: '10px' }}>💯</span>
                        With its comprehensive approach, talentscore generates a personalized score for each individual, reflecting their overall talent profile. This score serves as a valuable metric for individuals to assess their own professional standing, benchmark themselves against peers, and identify areas for potential growth and improvement. Moreover, it provides companies and hiring managers with an objective measure of an individual's talent and suitability for specific roles or positions.                    </div>                    <br /><br />
                    <div className="d-flex">
                        <span style={{ fontSize: '26px', marginRight: '10px' }}>🔎</span>
                        talentscore empowers individuals to showcase their academic achievements and professional experiences, positioning them as desirable candidates for employment opportunities. By leveraging their talentscore, individuals can enhance their visibility to potential employers, distinguish themselves from competitors, and increase their chances of securing rewarding career prospects.                    </div>                    <br /><br />
                    <div className="d-flex">
                        <span style={{ fontSize: '26px', marginRight: '10px' }}>🏆</span>
                        In summary, talentscore is a comprehensive evaluation tool that assesses both companies and individuals based on their talent and professional attributes. By considering academic background, educational institutions attended, and work history, talentscore provides a holistic assessment of an individual's expertise and potential. This empowers individuals to showcase their talents, gain a competitive edge in the job market, and pursue rewarding career opportunities.                    </div>                </div>
            </div>
        </>
    )
}

export default About;