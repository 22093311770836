import "./index.scss";

const Hamburger=()=>{
    return(
        <>
        <div className="hamburger">
            <div className="burger burger1"></div>
            <div className="burger burger2"></div>
            <div className="burger burger3"></div>
        </div>
        </>
    )
}

export default Hamburger;