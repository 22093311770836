import { Column } from '@ant-design/plots';
import { useContext } from 'react';
import { CommonContext } from '../../../context/CommonContext';
import "../index.scss";


const ColumnGraphicOne = () => {
  const { companyDetail } = useContext(CommonContext);
  const data = [];

  Object.keys(companyDetail.employeeScoreDto.employeeScoreDetailDto).map((key, index) => {
    data.push({
      "employee": `${index + 1}`,
      "score": Number(companyDetail.employeeScoreDto.employeeScoreDetailDto[key].toFixed(1))
    });
  });

  const config = {
    data,
    xField: 'employee',
    yField: 'score',
    label: {
      position: 'middle',
      style: {
        fill: '#ffffff',
        opacity: 0.6,
        fontSize: 12,
      },
    },

    columnStyle: {
      fill: `#02283B`,
      stroke: '#02283B',
      lineWidth: 1,
      radius: [16, 16, 0, 0],
    },
    tooltip: false,
  };
  return (
    <>
      <div>
        <span className='container-title'>Employee Score Distribution</span>
        <div className='graphic'>
          <div className='graphic-left'>
            <Column renderer='svg' {...config} xAxis={{ title: { text: 'Score' } }} yAxis={{
              title: { text: 'Percentage' }, label: {
                formatter: (value) => `${value}%`,
              },
            }} />
          </div>
          <div className='graphic-right'>
            <span>Avg. employee score</span>
            <span className='bold'>{Number(companyDetail.employeeScoreDto.avgScore).toFixed(1)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColumnGraphicOne;