import { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import {
  GetAllCompanies,
  GetAllCompaniesSearch,
  GetCount,
  GetCountryOrIndustry,
} from "../../services/allservices";
import { Link, useSearchParams } from "react-router-dom";
import "./index.scss";
import SearchBox from "./SearchBox";
import { ClipLoader } from "react-spinners";
import { CommonContext } from "../../context/CommonContext";
import CompanyLogo from "../../assets/images/company-logo.png";
import Rankings from "./Rankings";
import { Tooltip, Empty } from "antd";
import { includes, split } from "lodash";
import Utils from "../../helpers/utils";

const Companies = () => {
  const [columns, setColumns] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [counts, setCounts] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { searchData, setRank, isSearch, mobileMatched } =
    useContext(CommonContext);
  const [searchResult, setSearchResult] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoadSearch, setIsLoadSearch] = useState(false);
  const response = JSON.parse(sessionStorage.getItem("countInfo"));

  const country = searchParams.get("country");
  const industry = searchParams.get("industry")?.replace("&", "%26");
  const currentUrl = window.location.href;

  useEffect(() => {
    if (response && (country || industry) !== null) {
      getCountryOrIndustry(country, industry);
      setCounts(() => response.data);
    } else if (!response && (country || industry) !== null) {
      getCounts();
      getCountryOrIndustry(country, industry);
    } else {
      if (response === null) {
        setFilteredCompanies([]);
        getCompany();
        getCounts();
      } else {
        setFilteredCompanies([]);
        getCompany();
        setCounts(() => response.data);
      }
    }
  }, [country, industry]);

  const getCountryOrIndustry = async (country, industry) => {
    setIsLoading(true);
    const response = await GetCountryOrIndustry(country, industry);
    if (response) {
      setFilteredCompanies(() => response.data);
    }
    setIsLoading(false);
  };

  const getCompany = async () => {
    setIsLoading(true);
    const response = await GetAllCompanies();
    if (response) {
      setCompanies(() => response.data);
    }
    setIsLoading(false);
  };
  const getCounts = async () => {
    setIsLoading(true);
    const responseCount = await GetCount();
    if (responseCount) {
      setCounts(() => responseCount.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setSearchResult([]);
    if (searchData.length >= 2) {
      getCompanySearch(searchData);
    }
  }, [searchData]);

  const getCompanySearch = async (searchData) => {
    setIsLoadSearch(true);
    const response = await GetAllCompaniesSearch(searchData);
    if (response) {
      setSearchResult(() => response.data);
    }
    setIsLoadSearch(false);
  };

  useEffect(() => {
    setColumns([
      {
        title: "Rank",
        align: "right",
        render: (value, item, index) => index + 1,
      },
      {
        title: "Name",
        dataIndex: ["name", "logo", "id"],
        align: "left",
        render: (text, record) => (
          <Link to={`/detail/${record["id"]}`}>
            {record["logo"] && record["logo"].substring(0, 2) !== "77" ? (
              <img
                src={`data:image/jpeg;base64,${record["logo"]}`}
                alt="logo"
              />
            ) : (
              <img src={CompanyLogo} alt="companyLogo" />
            )}
            {mobileMatched ? (
              <span>{record["name"]} </span>
            ) : record["name"].length > 30 ? (
              <span>
                {
                  <Tooltip title={record["name"]}>
                    {record["name"].substring(0, 30)}...{" "}
                  </Tooltip>
                }
              </span>
            ) : (
              <span>{record["name"]} </span>
            )}
          </Link>
        ),
      },
      {
        title: "talentscore",
        dataIndex: "score",
        render: (text, record) =>
          <span style={{ color: Utils.scoreColorChecker(record["score"]) }}>
            {record["score"]}
          </span>
      },
      {
        title: "Company Size",
        dataIndex: `worker`,
        width: 200,
        render: (text) => Utils.companySizeChecker(text),
      },
      {
        title: "Industry",
        dataIndex: "industry",
        align: "left",
        render: (text) =>
          text === undefined || text === null || text === "NOT_FOUND" ? (
            "N/A"
          ) : String(text).length > 30 ? (
            <span>
              {<Tooltip title={text}>{text.substring(0, 30)}... </Tooltip>}
            </span>
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: "Country",
        dataIndex: "foundCountry",
        align: "left",
        render: (text) =>
          text !== undefined && text !== null && text !== "NOT_FOUND"
            ? text
            : "N/A",
      },
    ]);
  }, [country]);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  const text = "Unable find your company?";

  return (
    <>
      <Rankings />
      {isLoading ? (
        <div
          style={{
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "37",
            height: "calc(100vh - 132px)",
            width: "100%",
          }}
        >
          <ClipLoader color={"#03283a"} loading={isLoading} size={100} />
        </div>
      ) : (
        <div className="data-big-wrapper">
          <div className={isSearch ? "titles isSearch" : "titles"}>
            <div className="companies-title">
              Companies employing the top talent in the world
            </div>
            <div className="companies-subtitles">
              <div className="subtitle">
                Companies:{" "}
                <span className="ts-font-light-16">
                  {!isNaN(counts.companyCount)
                    ? Number(counts.companyCount).toLocaleString()
                    : ""}
                </span>
              </div>
              <div className="subtitle">
                Schools:{" "}
                <span className="ts-font-light-16">
                  {!isNaN(counts.schoolCount)
                    ? Number(counts.schoolCount).toLocaleString()
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div>
            <SearchBox />
          </div>
          <div className="table-container">
            {isLoadSearch ? (
              <div
                style={{
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  display: "flex",
                  justifyContent: "center",
                  zIndex: "37",
                  height: "calc(100vh - 500px)",
                  width: "100%",
                }}
              >
                <ClipLoader
                  color={"#03283a"}
                  loading={isLoadSearch}
                  size={100}
                />
              </div>
            ) : searchResult.length >= 1 ? (
              <>
                {searchResult
                  .filter((item, index) => index < 8)
                  .map((item, index) => (
                    <div className="search" key={index}>
                      <Link to={`/detail/${item.id}`}>
                        {item.logo !== null &&
                          item.logo &&
                          item.logo.substring(0, 2) !== "77" ? (
                          <img
                            src={`data:image/jpeg;base64,${item.logo}`}
                            alt="logo"
                          />
                        ) : (
                          <img src={CompanyLogo} alt="companyLogo" />
                        )}
                        {item.name}
                      </Link>
                    </div>
                  ))}
                <div className="getListedWrap">
                  {text}{" "}
                  <Link to="/getListed" className="getListed">
                    Get listed.
                  </Link>
                </div>
              </>
            ) : searchResult.length === 0 && searchData.length >= 2 ? (
              <div className="getListedWrap">
                {text}{" "}
                <Link to="/getListed" className="getListed">
                  Get listed.
                </Link>
              </div>
            ) : searchResult.length === 0 && searchData.length < 2 ? (
              <div className="table">
                {columns && (country || industry) !== null ? (
                  filteredCompanies.length === 0 ? (
                    <Empty />
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={filteredCompanies}
                      onChange={onChange}
                      rowKey="id"
                      pagination={false}
                      onRow={(record, Index) => {
                        return {
                          onClick: () => {
                            setRank(Index + 1);
                          },
                        };
                      }}
                    />
                  )
                ) : (
                  <Table
                    columns={columns}
                    dataSource={companies}
                    onChange={onChange}
                    rowKey="id"
                    pagination={false}
                    onRow={(record, Index) => {
                      return {
                        onClick: () => {
                          setRank(Index + 1);
                        },
                      };
                    }}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Companies;
