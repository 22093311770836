import { useContext, useState } from "react";
import { CommonContext } from "../../../context/CommonContext";
import "../index.scss";
import CompanyLogo from "../../../assets/images/company-logo.png";
import Modal from 'react-modal';
import Utils from "../../../helpers/utils";


const CompanyInfo = () => {
    const { rank } = useContext(CommonContext);
    const { companyDetail } = useContext(CommonContext);
    const [modalIsOpen, setIsOpen] = useState(false);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }
    console.log(companyDetail);
    return (
        <>
            <div className="company-info-one">
                {companyDetail.logo !== null &&
                    companyDetail.logo &&
                    companyDetail.logo.substring(0, 2) !== "77" ?
                    (<img
                        src={`data:image/jpeg;base64,${companyDetail.logo}`}
                        alt="logo"
                    />) : (<img src={CompanyLogo} alt="companyLogo" />)}
                <span>{companyDetail.name}</span>
                <span className="company-rank">
                    <span>{rank}</span>
                    <span>Rank</span>
                </span>
            </div>
            <div className="company-info-two">
                <div className="info-one">
                    <div className="company-country">
                        <span>{companyDetail.foundCountry == null || companyDetail.foundCountry == "NOT_FOUND" ? "N/A" : companyDetail.foundCountry}</span>
                        <span>Country</span>
                    </div>
                    <div className="company-industry">
                        <span>{companyDetail.industry == null || companyDetail.industry == "NOT_FOUND" ? "N/A" : companyDetail.industry}</span>
                        <span>Industry</span>
                    </div>
                </div>
                <div className="info-one">
                    <div className="compnay-talentscore">
                        <span className="score">{companyDetail.score == null || companyDetail.score == "NOT_FOUND" ? "N/A" : companyDetail.score}</span>
                        <span>talentscore</span>
                    </div>
                    <div className="company-size">
                        <span>{companyDetail.worker == null || companyDetail.worker == "NOT_FOUND" ? "N/A" : Utils.companySizeChecker(companyDetail.worker)}</span>
                        <span>Company Size</span>
                    </div>
                </div>
            </div>
            <div className="company-info-three"><div>
                {companyDetail.note == null || companyDetail.note === "NOT_FOUND" ? "N/A" : 
                    <p className="detail-notes">{companyDetail.note.length > 850 ? <p>{companyDetail.note.substring(0, 850)}... {<button onClick={openModal} className="readMoreButton">Read More</button>}</p> : companyDetail.note} </p>
                }
            </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button onClick={closeModal}>close</button>
                <div>{companyDetail.note == null || companyDetail.note === "NOT_FOUND" ? "N/A" : companyDetail.note}</div>

            </Modal>
        </>
    )
}

export default CompanyInfo;