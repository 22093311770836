import "./index.scss";

const Footer = () => {
    return (
        <>
            <div className="footer-container footer">
                <div className="footer-left ts-font-regular-14">
                &copy; 2023 talentscore.io
                </div>
                <div className="footer-right">
                <div className="d-flex align-items-center justify-content-end ts-font-medium-14" style={{ listStyleType : 'none'}}>
                        <div className="ms-2 ts-font-regular"><a className="ts-font-regular-14 ts-color-primary text-decoration-none" href="/about">About</a></div>
                        <div className="ms-2 ts-font-regular"><a className="ts-font-regular-14 ts-color-primary text-decoration-none" href="/contact">Contact</a></div>
                        <div className="ms-2 ts-font-regular"><a className="ts-font-regular-14 ts-color-primary text-decoration-none" href="disclaimer">Disclaimer</a></div>
                        <div className="ms-2 ts-font-regular"><a className="ts-font-regular-14 ts-color-primary text-decoration-none" href="privacy-policy">Privacy Policy</a></div>
                    </div>
                    {/* <h4>Contact</h4>
                    For inquiries or if you want to report a problem write to <a href="mailto:support@talentscore.io">support@talentscore.io</a> */}                    
                </div>

            </div>
        </>
    )
}

export default Footer;