import React from 'react'
import "../../../App.scss"
import "./index.scss"

import { LeftSideMenu } from '../LeftSideMenu'
import Footer from '../../Footer'
import Rankings from '../../Companies/Rankings'

export const PrivacyPolicy = () => {
  return (
    <>
      <Rankings />
      <div className="col-12 d-flex">
        <div className='d-flex justify-content-end col-3 p-5 mt-5'>
          <LeftSideMenu />
        </div>
        <div className='col-9 pt-5 ps-5 mt-4'>
          <h2>Privacy Policy</h2>
          <subtitle className="ts-font-regular-12">Last Update: 16.06.2023</subtitle>
          <div className='mt-4 mb-5 w-75 ts-font-regular-16'>
            
            At talentscore, we are committed to protecting the privacy and confidentiality of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard the data obtained through our platform. By using talentscore, you consent to the practices described in this Privacy Policy.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Information Collection:</h5>
            We collect personal information provided by users voluntarily when they interact with our platform. This may include names, contact details, educational background, work history, and other relevant data. Additionally, we may collect non-personal information such as usage statistics and technical details through cookies or similar technologies.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Use of Information:</h5>
            The personal information we collect is used to provide and improve our services, including generating individual and company talent scores, facilitating comparisons, and offering personalized recommendations. We may also use the information to communicate with users, respond to inquiries, and provide relevant updates and notifications.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Data Sharing:</h5>
            talentscore respects the confidentiality of personal information and does not sell, rent, or lease it to third parties. However, we may share data with trusted service providers or business partners who assist us in delivering our services. We ensure that these entities adhere to strict privacy and security standards.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Data Security:</h5>
            We employ industry-standard security measures to protect the personal information submitted to talentscore. This includes encryption, firewalls, access controls, and regular system monitoring. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Data Retention:</h5>
            We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by applicable laws and regulations. We take reasonable steps to ensure the accuracy and integrity of the data and provide users with the ability to update or delete their information.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Third-Party Links:</h5>
            talentscore may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We encourage users to review the privacy policies of such third-party platforms before providing any personal information.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Children's Privacy:</h5>
            talentscore is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from minors. If we become aware of any personal information collected from individuals under 16, we will take immediate steps to delete such data from our records.
            <br /><br />
            <h5 className='ts-font-semibold-16'>Changes to the Privacy Policy:</h5>
            We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Users will be notified of any significant changes, and the revised Privacy Policy will be made available on our website.
            <br /><br />
            Please note that this Privacy Policy only applies to talentscore and does not cover the privacy practices of any third-party websites or services linked to our platform. We encourage users to review the privacy policies of such external platforms.
            <br /><br />
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a className='ts-font-bold-16' href="mailto:support@talentscore.io">support@talentscore.io</a>.
        </div>
        </div>
      </div>
    </>
  )
}
