/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { CommonContext } from "../../context/CommonContext";
import Rankings from "../Companies/Rankings";
import { message } from "antd";
import axios from "axios";
import { BASE_URL, TOKEN } from "../../helpers/constants";

const Contact = () => {
    const { setSearchData, mobileMatched } = useContext(CommonContext);

    useEffect(() => {
        setSearchData("");
    }, [setSearchData]);

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Too Short!')
            .required('This field cannot be left blank.'),
        email: Yup.string()
            .email('Invalid email address.')
            .required('This field cannot be left blank.'),
        companyName: Yup.string()
            .required('This field cannot be left blank.'),
        message: Yup.string()
            .min(2, 'Too Short!')
            .max(400, 'Too Long!')
            .required('This field cannot be left blank.'),
        mobileNumber: Yup.string()
            .matches(/^\d+$/, "Mobile number can only contain digits")
            .test('len', 'Mobile number needs to be between 11 and 15 digits', val => val.length >= 10 && val.length <= 15)
            .required('This field cannot be left blank.'),
    });

    const initialValues = {
        name: '',
        email: '',
        companyName: '',
        message: '',
        mobileNumber: '',
    };

    const onSubmit = async (values) => {

        const headers = {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
        };

        const response = await axios.post(BASE_URL + 'contact-us', {
            name: values.name,
            companyName: values.companyName,
            email: values.email,
            mobileNumber: values.mobileNumber.toString(),
            message: values.message,
        }, {
            headers: headers,
        });
        console.log(response)
        if (response.data.code === 200) {
            message.success({
                type: 'success',
                content: 'Thank you. We will be in touch shortly. Redirecting to home page in 5 seconds.',
                duration: 5,
            }).then(() => {
                window.location.href = "/";
            });
        } else {
            message.error({
                type: 'error',
                content: 'There was an error sending your message. Please try again',
                duration: 5,
            });
        }

    };

    return (
        <>
            <Rankings />
            <div className="contact-container">
                <div className="form-container">
                    <span className="title ts-font-bold">Get in<span className="red-title ts-font-bold"> Touch</span> </span>
                    <span className="text ts-font-regular">Don’t be a stranger, let’s talk 🖐</span>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={SignupSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValid }) => (
                                <Form>
                                    <Field className="ts-font-regular" id="name" name="name" placeholder="Name" />
                                    {errors.name && touched.name ? (<div className="text-danger error-message">{errors.name}</div>) : null}

                                    <Field className="ts-font-regular" id="companyName" name="companyName" placeholder="Company Name" />
                                    {errors.companyName && touched.companyName ? (<div className="text-danger error-message">{errors.companyName}</div>) : null}
                                    <Field
                                        className="ts-font-regular"
                                        id="email"
                                        name="email"
                                        placeholder="E-Mail"
                                        type="email"
                                    />
                                    {errors.email && touched.email ? (<div className="text-danger error-message">{errors.email}</div>) : null}
                                    <Field className="ts-font-regular" id="mobileNumber" type="number" name="mobileNumber" placeholder="Mobile Number" />
                                    {errors.mobileNumber && touched.mobileNumber ? (<div className="text-danger error-message">{errors.mobileNumber}</div>) : null}
                                    <Field
                                        className="ts-font-regular"
                                        id="message"
                                        name="message"
                                        placeholder="Your Message"
                                        component="textarea"
                                        rows="8"
                                    />
                                    {errors.message && touched.message ? (<div className="text-danger error-message">{errors.message}</div>) : null}
                                    <button type="submit" className="mt-4 ts-font-regular" disabled={!isValid}>Submit</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12065.766414477128!2d-74.275129!3d40.884127!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3010569fc04f5%3A0x15fab5325ef28625!2s12%20Daniel%20Rd%20318%20a3%2C%20Fairfield%2C%20NJ%2007004%2C%20Amerika%20Birle%C5%9Fik%20Devletleri!5e0!3m2!1str!2str!4v1692780501457!5m2!1str!2str"
                        style={{
                            width: "100%",
                            height: "630px",
                            border: "0",
                            allowfullscreen: "",
                            loading: "lazy",
                        }}></iframe>
                </div>
            </div>
        </>
    )
}

export default Contact;
