import "./index.scss";
import { Collapse, Dropdown, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CommonContext } from "../../../context/CommonContext";
import BottomIcon from "../../../assets/svg/bottom.svg";
import { GetIndustry } from "../../../services/allservices";
import { hover } from "@testing-library/user-event/dist/hover";

const Industries = () => {
  const { isActive, setActive, setSearchData, setShowedSearchTerm } =
    useContext(CommonContext);

  const items = [
    {
      type: "group",
      key: "0",
      children: [
        {
          key: "0-0",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Agriculture">
              Agriculture
            </Link>
          ),
        },
        {
          key: "0-1",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Arts">
              Arts
            </Link>
          ),
        },
        {
          key: "0-2",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Construction"
            >
              Construction
            </Link>
          ),
        },
        {
          key: "0-3",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Consumer Goods"
            >
              Consumer Goods
            </Link>
          ),
        },
        {
          key: "0-4",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Corporate Services"
            >
              Corporate Services
            </Link>
          ),
        },
        {
          key: "0-5",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Design">
              Design
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      key: "1",
      children: [
        {
          key: "1-0",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Education">
              Education
            </Link>
          ),
        },
        {
          key: "1-1",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Energy %26 Mining"
            >
              Energy & Mining
            </Link>
          ),
        },
        {
          key: "1-2",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Entertainment"
            >
              Entertainment
            </Link>
          ),
        },
        {
          key: "1-3",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Finance">
              Finance
            </Link>
          ),
        },
        {
          key: "1-4",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Hardware %26 Networking"
            >
              Hardware & Networking
            </Link>
          ),
        },
        {
          key: "1-5",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Health Care">
              Health Care
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      key: "2",
      children: [
        {
          key: "2-0",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Legal">
              Legal
            </Link>
          ),
        },
        {
          key: "2-1",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Manufacturing"
            >
              Manufacturing
            </Link>
          ),
        },
        {
          key: "2-2",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Media %26 Communications"
            >
              Media & Communications
            </Link>
          ),
        },
        {
          key: "2-3",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Nonprofit">
              Nonprofit
            </Link>
          ),
        },

        {
          key: "2-4",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Public Administration"
            >
              Public Administration
            </Link>
          ),
        },
        {
          key: "2-5",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Public Safety"
            >
              Public Safety
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      key: "3",
      children: [
        {
          key: "3-0",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Real Estate">
              Real Estate
            </Link>
          ),
        },
        {
          key: "3-1",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Recreation %26 Travel"
            >
              Recreation & Travel
            </Link>
          ),
        },
        {
          key: "3-2",
          label: (
            <Link className=" text-decoration-none" to="/?industry=Retail">
              Retail
            </Link>
          ),
        },
        {
          key: "3-3",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Software %26 IT Services"
            >
              Software & IT Services
            </Link>
          ),
        },
        {
          key: "3-4",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Transportation %26 Logistics"
            >
              Transportation & Logistics
            </Link>
          ),
        },
        {
          key: "3-5",
          label: (
            <Link
              className=" text-decoration-none"
              to="/?industry=Wellness %26 Fitness"
            >
              Wellness & Fitness
            </Link>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <Dropdown className="w-100 " menu={{ items }} trigger={["click"]}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setActive(!isActive);
            setShowedSearchTerm("");
            setSearchData("");
          }}
        >
          <Space className="rankings-link">
            Ranking by Industries <img src={BottomIcon} alt={""} />
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default Industries;
