import { Input } from "antd";
import { useContext, useEffect, useMemo } from "react";
import searchIcon from "../../../assets/svg/searchIcon.svg";
import { CommonContext } from "../../../context/CommonContext";
import "./index.scss";
import debounce from "lodash.debounce";

const SearchBox = () => {
  const { setSearchData, setShowedSearchTerm, showedSearchTerm } =
    useContext(CommonContext);

  const handleChange = (e) => {
    setShowedSearchTerm(e.target.value);
  };
  const handleDebounceChange = (e) => {
    setSearchData(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleDebounceChange, 400);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  return (
    <div className="search-box">
      <img src={searchIcon} alt="Search" />

      <Input
        className="searchInput"
        placeholder="Company name"
        onChange={(e) => {
          handleChange(e);
          debouncedResults(e);
        }}
        value={showedSearchTerm}
      />
    </div>
  );
};

export default SearchBox;
