import axios from "axios";

import { API_BASE_URL, BASE_URL } from "./constants";
import history from "./history";
import { message } from "antd";

axios.defaults.baseURL = API_BASE_URL;


const httpService = async ({
  method,
  url,
  data,
  options,
  dispatch,
  headers,
}) => {
  let response = null;
  try {
    response = await axios({
      method,
      url,
      data,
      headers,
    });

    if (options && options.successMessage) {
      message.success(options.successMessage);
    }

    if (options && options.redirectIfSuccess) {
      history.push(options.redirectIfSuccess);
    }
  } catch (err) {
    if (options && options.redirectIfFailed) {
      history.push(options.redirectIfFailed);
    }
    if (options && options.errorMessage) {
      message.error(options.errorMessage);
    }
    if (options && options.showErrorMessageFromServer) {
      if (err.response) {
        if (
          err.response.status === 403 ||
          (err.response.data &&
            (err.response.data.status === 403 ||
              err.response.data.error === "Forbidden" ||
              err.response.data.message === "Access Denied"))
        ) {
          localStorage.removeItem("token");
          history.push("/login");
        } else {
          message.error(err.response.data.message);
        }
      } else {
        message.error(err.toString());
      }
    }
    console.log(err.response);
    throw err;
  }

  return response;

};

export default httpService;
