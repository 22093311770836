import "./index.scss";
import { Dropdown, Space } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import BottomIcon from "../../../assets/svg/bottom.svg";
import Au from "../../../assets/flags/au.png";
import Br from "../../../assets/flags/br.png";
import Ca from "../../../assets/flags/ca.png";
import Ch from "../../../assets/flags/ch.png";
import Cl from "../../../assets/flags/cl.png";
import Cn from "../../../assets/flags/cn.png";
import De from "../../../assets/flags/de.png";
import Es from "../../../assets/flags/es.png";
import Fi from "../../../assets/flags/fi.png";
import Fr from "../../../assets/flags/fr.png";
import Hk from "../../../assets/flags/hk.png";
import Id from "../../../assets/flags/id.png";
import Il from "../../../assets/flags/il.png";
import In from "../../../assets/flags/in.png";
import It from "../../../assets/flags/it.png";
import Jp from "../../../assets/flags/jp.png";
import Kr from "../../../assets/flags/kr.png";
import Mx from "../../../assets/flags/mx.png";
import My from "../../../assets/flags/my.png";
import Nl from "../../../assets/flags/nl.png";
import Nz from "../../../assets/flags/nz.png";
import Pl from "../../../assets/flags/pl.png";
import Ru from "../../../assets/flags/ru.png";
import Sa from "../../../assets/flags/sa.png";
import Se from "../../../assets/flags/se.png";
import Sg from "../../../assets/flags/sg.png";
import Th from "../../../assets/flags/th.png";
import Tr from "../../../assets/flags/tr.png";
import Tw from "../../../assets/flags/tw.png";
import Uk from "../../../assets/flags/uk.png";
import Us from "../../../assets/flags/us.png";
import Za from "../../../assets/flags/za.png";
import { CommonContext } from "../../../context/CommonContext";

const Countries = () => {
  const { isActive, setActive, setSearchData, setShowedSearchTerm } =
    useContext(CommonContext);

  const items = [
    {
      type: "group",
      label: "Most Popular",
      key: "0",
      children: [
        {
          key: "0-0",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=United States"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Us}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              United States
            </Link>
          ),
        },
        {
          key: "0-1",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=United Kingdom"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Uk}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              United Kingdom
            </Link>
          ),
        },
        {
          key: "0-2",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Canada"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Ca}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Canada
            </Link>
          ),
        },
        {
          key: "0-3",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Germany"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={De}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Germany
            </Link>
          ),
        },
        {
          key: "0-4",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Brazil"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Br}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Brazil
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      label: "America",
      key: "1",
      children: [
        {
          key: "1-0",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=United States"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Us}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              United States
            </Link>
          ),
        },
        {
          key: "1-1",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Canada"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Ca}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Canada
            </Link>
          ),
        },
        {
          key: "1-2",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Mexico"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Mx}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Mexico
            </Link>
          ),
        },
        {
          key: "1-3",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Brazil"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Br}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Brazil
            </Link>
          ),
        },
        {
          key: "1-4",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Chile"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Cl}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Chile
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      label: "Europe",
      key: "2",
      children: [
        {
          key: "2-0",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Germany"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={De}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Germany
            </Link>
          ),
        },
        {
          key: "2-1",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=United Kingdom"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Uk}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              United Kingdom
            </Link>
          ),
        },
        {
          key: "2-2",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=France"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Fr}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              France
            </Link>
          ),
        },
        {
          key: "2-3",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Spain"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Es}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Spain
            </Link>
          ),
        },
        {
          key: "2-4",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Netherlands"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Nl}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Netherlands
            </Link>
          ),
        },
        {
          key: "2-5",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Sweden"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Se}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Sweden
            </Link>
          ),
        },
        {
          key: "2-6",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Italy"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={It}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Italy
            </Link>
          ),
        },
        {
          key: "2-7",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Switzerland"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Ch}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Switzerland
            </Link>
          ),
        },
        {
          key: "2-8",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Poland"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Pl}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Poland
            </Link>
          ),
        },
        {
          key: "2-9",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Finland"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Fi}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Finland
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      label: "Asia",
      key: "3",
      children: [
        {
          key: "3-0",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=China"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Cn}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              China
            </Link>
          ),
        },
        {
          key: "3-1",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Japan"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Jp}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Japan
            </Link>
          ),
        },
        {
          key: "3-2",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=South Korea"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Kr}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              South Korea
            </Link>
          ),
        },
        {
          key: "3-3",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Hong Kong"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Hk}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Hong Kong
            </Link>
          ),
        },
        {
          key: "3-4",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Singapore"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Sg}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Singapore
            </Link>
          ),
        },
        {
          key: "3-5",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Indonesia"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Id}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Indonesia
            </Link>
          ),
        },
        {
          key: "3-6",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=India"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={In}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              India
            </Link>
          ),
        },
        {
          key: "3-7",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Malaysia"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={My}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Malaysia
            </Link>
          ),
        },
        {
          key: "3-8",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Taiwan"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Tw}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Taiwan
            </Link>
          ),
        },
        {
          key: "3-9",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Thailand"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Th}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Thailand
            </Link>
          ),
        },
      ],
    },
    {
      type: "group",
      label: "Others",
      key: "4",
      children: [
        {
          key: "4-0",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Australia"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Au}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Australia
            </Link>
          ),
        },
        {
          key: "4-1",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=New Zealand"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Nz}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              New Zealand
            </Link>
          ),
        },
        {
          key: "4-2",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Israel"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Il}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Israel
            </Link>
          ),
        },
        {
          key: "4-3",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Saudi Arabia"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Sa}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Saudi Arabia
            </Link>
          ),
        },
        {
          key: "4-4",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Turkey"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Tr}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Turkey
            </Link>
          ),
        },
        {
          key: "4-5",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=Russia"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Ru}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              Russia
            </Link>
          ),
        },
        {
          key: "4-6",
          label: (
            <Link
              className="contry-flags text-decoration-none"
              to="/?country=South Africa"
              onClick={() => {
                setSearchData("");
              }}
            >
              <img
                src={Za}
                alt={""}
                style={{ width: "25px", height: "25px" }}
              />
              South Africa
            </Link>
          ),
        },
      ],
    },
  ];
  return (
    <>
      <Dropdown
        className="w-100"
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setActive(!isActive);
            setShowedSearchTerm("");
            setSearchData("");
          }}
        >
          <Space className="rankings-link" style={{ marginRight: 0 }}>
            Ranking by Countries
            <img src={BottomIcon} alt={""} />
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default Countries;
