import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetAllCompanies = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "company/all",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (!response) return;
  return response.data;
};
export const GetAllCompaniesDetails = async (id) => {
  console.log(id);
  const response = await httpService({
    method: "get",
    url: BASE_URL + "company/" + id,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (!response) return;
  return response;
};
export const GetAllCompaniesSearch = async (keyword) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "company/search/" + keyword,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (!response) return;
  return response.data;
};

export const GetCount = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "count",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (!response) return;
  sessionStorage.setItem("countInfo", JSON.stringify(response.data));
  return response.data;
};

export const GetCountryOrIndustry = async (country, industry) => {
  let url = BASE_URL + "company/all?";

  if (country) {
    url += `country=${country}`;
  } else if (industry) {
    url += `industry=${industry}`;
  }

  const response = await httpService({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response) return;
  return response.data;
};
