import { useContext } from 'react';
import { CommonContext } from '../../../context/CommonContext';
import "../index.scss";


const PieGraphicTwo = () => {
    const { companyDetail } = useContext(CommonContext);

    return (
        <>
            <div>
                <span className='container-title'>Top Skills</span>
                <div className='graphic'>
                    <div className='graphic-left'>
                    {
                        companyDetail.employeeTopSkillDtos.map((item)=>{ return(
                                <div className="skill">
                                    <span className='skill-name'>{item.mainSkillName}: </span>
                                    <span className='value font-weight-bold'>{Number(item.percentage).toFixed(1)}%</span>
                                </div>
                        )
                        })
                    }
                    </div>
                    <div className='graphic-right'>
                        <span>Top skills in the company</span>
                        <ul className='bold'>
                        {companyDetail.employeeTopSkillDtos.filter((item, index)=> index < 2).map((item)=>{ return(
                            <li>{item.mainSkillName}</li>
                            )
                        })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PieGraphicTwo;