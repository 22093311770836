import React from 'react'
import "../../../App.scss"
import "./index.scss"

import { LeftSideMenu } from '../LeftSideMenu'
import Footer from '../../Footer'
import Rankings from '../../Companies/Rankings'

export const Disclaimer = () => {
    return (
        <>
            <Rankings />
            <div className="col-12 d-flex">
                <div className='d-flex justify-content-end col-3 p-5 mt-5'>
                    <LeftSideMenu />
                </div>
                <div className='col-9 pt-5 ps-5 mt-4'>
                    <h2>Disclaimer</h2>
                    <subtitle className="ts-font-regular-12">Last Update: 16.06.2023</subtitle>
                    <div className='mt-4 mb-5 w-75 ts-font-regular-16'>
                        talentscore is an evaluation tool developed to assess companies and individuals based on various criteria, including educational background, academic institutions attended, and work history. While talentscore employs advanced algorithms and data analysis techniques to generate scores and rankings, it is important to note that the results provided by talentscore should be interpreted as indicators and not definitive measures of an individual's or company's worth or potential.
                        <br /><br />
                        talentscore is designed to offer insights and comparisons, but it should not be the sole determining factor in making decisions regarding employment, recruitment, or talent management. It is always recommended to consider a comprehensive evaluation process that includes additional factors, such as interviews, skill assessments, references, and personal judgment.
                        <br /><br />
                        Furthermore, talentscore relies on publicly available data and information provided by individuals and companies. While efforts are made to ensure accuracy, completeness, and currency of the data, it is possible that certain information may be missing or outdated. Therefore, users should independently verify and corroborate the information provided by talentscore before making any significant decisions based on the results.
                        <br /><br />
                        Additionally, talentscore does not endorse or promote any particular educational institution, company, or individual. The evaluation process is based on objective criteria, but personal preferences, biases, and contextual factors may influence the interpretation and application of the results.
                        <br /><br />
                        Lastly, talentscore cannot guarantee the future performance, success, or potential of individuals or companies. It is merely a tool designed to provide insights and facilitate decision-making processes. Individual achievements, growth, and contributions are influenced by numerous factors beyond the scope of talentscore's evaluation.
                        <br /><br />
                        Users of talentscore are encouraged to exercise their own judgment, consider multiple perspectives, and consult with relevant professionals or experts before making any consequential decisions. The creators and developers of talentscore shall not be held liable for any actions or decisions taken based on the information provided by the tool.
                    </div>
                </div>
            </div>
        </>
    )
}
