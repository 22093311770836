import React, { useState } from "react";
import Search from "antd/es/input/Search";
import { Bar } from "react-chartjs-2";
import { Col, Empty, List, Row, Spin, Table, Tag } from "antd";
import axios from "axios";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import "./index.scss";
import Utils from "../../helpers/utils";
import schoolLogo from "../../assets/svg/mdi_school-outline.svg";
import communicationLogo from "../../assets/svg/mdi_account-tie-voice-outline.svg";
import employersLogo from "../../assets/svg/mdi_domain.svg";
import commitmentLogo from "../../assets/svg/mdi_tag-heart-outline.svg";

Chart.register(CategoryScale);

const ResumeSearch = () => {
  const [searchData, setSearchData] = useState("");
  const [includesComma, setIncludesComma] = useState(true);
  const [loading, setLoading] = useState(false);
  const [salary, setSalary] = useState(null);
  const [resume, setResume] = useState(null);
  const [error, setError] = useState(false);
  const [responseCode, setResponseCode] = useState(null);
  const [isUserFound, setIsUserFound] = useState(false);
  const [isSalaryPredicted, setIsSalaryPredicted] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const handleSearch = async () => {
    if (!searchData.includes(",")) {
      return setIncludesComma(false);
    } else {
      setIncludesComma(true);
    }
    setIsSearched(true);
    setSalary(null);
    setError(false);
    setResume(null);
    setLoading(true);
    setIsSalaryPredicted(false);
    setIsUserFound(false);

    const BASE_URL = "https://app.talentscore.io/server/api/v1";

    const TEMPORARY_TOKEN =
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJvbWVyLmNlbGVwQGdpbml0YWxlbnQuY29tIiwic3Vic2NyaXB0aW9ucyI6WyJGcmVlIl0sInN1cm5hbWUiOiJDZWxlcCIsInJvbGVzIjpbXSwibmFtZSI6Ik9tZXIiLCJleHAiOjE3MDIyOTAxNDR9.Nyxgbr5cl7r9IjsqLopwvPFY2nP5ZgYA3mMLMM9yWpSX4Itisb6l0H3AoEAnvVzbOMQRyb3rZRO79Mf5tMANhg";

    try {
      const response = await axios.get(
        BASE_URL + "/scored-cv/searchbox/" + searchData,
        {
          headers: {
            Authorization: `${TEMPORARY_TOKEN}`,
          },
        }
      );

      setResponseCode(response?.data?.code);

      if (response?.data?.cv) {
        setIsUserFound(true);
        setResume(response?.data?.cv);

        const resumeId = response?.data?.cv?.id;

        try {
          const salaryResponse = await axios.get(
            BASE_URL + "/salary/predict/" + resumeId,
            {
              headers: {
                Authorization: `${TEMPORARY_TOKEN}`,
              },
            }
          );

          setSalary(salaryResponse?.data);
          setIsSalaryPredicted(true);
        } catch {
          setIsSalaryPredicted(false);
        }
        setSearchData("");
      } else {
        console.error("resume.id is undefined.");
        setIsUserFound(false);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Supervision Coefficient",
      dataIndex: "supervisionCoef",
      key: "supervisionCoef",
    },
    {
      title: "Education Coefficient",
      dataIndex: "eduCoef",
      key: "eduCoef",
    },
    {
      title: "Company Coefficient",
      dataIndex: "lastCompCoef",
      key: "lastCompCoef",
    },
    {
      title: "Country Coefficient",
      dataIndex: "countryCoef",
      key: "countryCoef",
    },
    {
      title: "Salary Coefficient",
      dataIndex: "salaryCoef",
      key: "salaryCoef",
    },
  ];

  const salaryColumn = [
    {
      title: "Min",
      dataIndex: "predictedMinSalary",
      key: "predictedMinSalary",
    },
    {
      title: "Median",
      dataIndex: "salaryMedian",
      key: "salaryMedian",
    },
    {
      title: "Max",
      dataIndex: "predictedMaxSalary",
      key: "predictedMaxSalary",
    },
  ];

  const salaryData = [
    {
      key: "1",
      predictedMinSalary:
        salary?.predictedMinSalary?.toLocaleString("en-US") + " USD",
      salaryMedian: salary?.salaryMedian?.toLocaleString("en-US") + " USD",
      predictedMaxSalary:
        salary?.predictedMaxSalary?.toLocaleString("en-US") + " USD",
    },
  ];

  const data = [
    {
      key: "1",
      supervisionCoef: salary?.supervisionCoef,
      eduCoef: salary?.eduCoef,
      lastCompCoef: salary?.lastCompCoef,
      countryCoef: salary?.countryCoef,
      salaryCoef: salary?.salaryCoef,
    },
  ];

  const options = {
    scales: {
      y: {
        min: 0,
        max: () => maxGraphicValue(),
        stepSize: 20,
      },
    },
    responsive: true,
    plugins: {
      legend: null,
      title: {
        display: true,
        text: "Annual Gross Salary Distribution of Similar Profiles",
      },
    },
  };

  const BarChart = ({ data }) => {
    return <Bar data={data} options={options} />;
  };

  const maxGraphicValue = () => {
    const salaryIntervals = salary?.salaryIntervalsPercentages;

    if (salaryIntervals) {
      const values = Object.values(salaryIntervals);

      const maxValue = Math.max(...values);
      const top = Math.round((maxValue + 20) / 10) * 10;

      return top;
    }
  };

  const barChartData = {
    labels: salary?.salaryIntervalsYearly,
    datasets: [
      {
        label: "Salary Range Percentages",
        backgroundColor: "rgba(2, 40, 59,1)",
        borderColor: "rgba(2, 40, 59,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(2, 40, 59,0.6)",
        hoverBorderColor: "rgba(2, 40, 59,1)",
        data: [
          salary?.salaryIntervalsPercentages?.colOne,
          salary?.salaryIntervalsPercentages?.colTwo,
          salary?.salaryIntervalsPercentages?.colThree,
          salary?.salaryIntervalsPercentages?.colFour,
          salary?.salaryIntervalsPercentages?.colFive,
          salary?.salaryIntervalsPercentages?.colSix,
          salary?.salaryIntervalsPercentages?.colSeven,
          salary?.salaryIntervalsPercentages?.colEight,
        ],
      },
    ],
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="width-responsive" style={{ marginBlock: 50 }}>
        <div>
          <Search
            placeholder="Example: John Smith,ABC Corporation"
            size="large"
            onPressEnter={handleSearch}
            onSearch={handleSearch}
            onChange={(e) => setSearchData(e.target.value)}
            value={searchData}
          ></Search>
        </div>

        {searchData.length > 0 && !includesComma && (
          <div>
            <p style={{ color: "red", margin: 10 }}>
              Search term must contain a comma!
            </p>
          </div>
        )}
        {isSearched &&
          (loading ? (
            <div style={loadingStyle}>
              <Spin></Spin>
            </div>
          ) : (responseCode === 200 || !error) && isUserFound ? (
            <div className="mt-5">
              {resume?.fullName && (
                <div style={sectionHeader}>
                  <Row justify={"space-around"}>
                    <Col span={12}>
                      <h4 className="header">{resume.fullName} </h4>
                      <div className="title">{resume.title}</div>
                      <hr style={{ opacity: 0.1 }}></hr>
                      <Row align={"middle"} justify={"start"}>
                        <Col>
                          <div className="ts-font-regular-16 dot">
                            {resume?.experiences[0]?.company?.name}
                          </div>
                        </Col>
                        <Col>
                          <div className="dot">
                            <img
                              src={`data:image/png;base64,${resume?.experiences[0]?.company?.logo}`}
                              alt="Company Logo"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: 10,
                              }}
                            ></img>
                          </div>
                        </Col>
                        <Col>
                          <Row align={"middle"} className="ts-font-regular-16">
                            Company Score :
                            <div
                              style={{
                                marginLeft: 5,
                                color: Utils.scoreColorChecker(
                                  resume?.experiences[0]?.company?.score
                                ),
                              }}
                            >
                              {resume?.experiences[0]?.company?.score}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <Row></Row>
                      <Row align={"middle"} justify={"start"}>
                        <Col>
                          <div className="ts-font-regular-16 country dot">
                            {resume?.experiences[0]?.company?.industry}
                          </div>
                        </Col>
                        <Col>
                          <div className="ts-font-regular-16 country">
                            {resume?.experiences[0]?.company?.worker} employees
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="ts-font-regular-16 country mt-1">
                            {resume.foundCountry}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 15 }}></Row>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        paddingRight: 40,
                        display: "grid",
                        gap: 5,
                        maxBlockSize: 230,
                      }}
                    >
                      <Row
                        style={{
                          margin: "10px 0 10px 0",
                          borderBottom: "groove",
                          borderColor: "#E32643",
                          paddingBottom: 10,
                        }}
                        justify={"space-between"}
                        align={"middle"}
                      >
                        <Col
                          className="ts-font-bold-20 gradient"
                          style={{ fontSize: 28 }}
                        >
                          Score
                        </Col>
                        <Col>
                          {resume?.talentScore ? (
                            <Tag
                              className="tag-scores ts-font-bold-16"
                              color={Utils.scoreColorChecker(
                                resume.talentScore
                              )}
                            >
                              {resume.talentScore}
                            </Tag>
                          ) : (
                            <div
                              style={{ color: "gray" }}
                              className="ts-font-light-16"
                            >
                              N/A
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row justify={"space-between"}>
                        <Col>
                          <div className="ts-font-regular-16">
                            <img
                              style={{ marginRight: 8 }}
                              src={employersLogo}
                              alt="Employers Logo"
                            ></img>
                            <>Employers</>
                          </div>
                        </Col>
                        <Col>
                          {resume?.experienceScore ? (
                            <div
                              style={{
                                color: Utils.scoreColorChecker(
                                  resume.experienceScore
                                ),
                              }}
                              className="ts-font-bold-16"
                            >
                              {resume.experienceScore}
                            </div>
                          ) : (
                            <div
                              style={{ color: "gray" }}
                              className="ts-font-light-16"
                            >
                              N/A
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row justify={"space-between"}>
                        <Col>
                          <div className="ts-font-regular-16">
                            <img
                              style={{ marginRight: 8 }}
                              src={schoolLogo}
                              alt="Schools Logo"
                            ></img>
                            <>Schools</>
                          </div>
                        </Col>
                        <Col>
                          {resume?.educationScore ? (
                            <div
                              style={{
                                color: Utils.scoreColorChecker(
                                  resume.educationScore
                                ),
                              }}
                              className="ts-font-bold-16"
                            >
                              {resume.educationScore}
                            </div>
                          ) : (
                            <div
                              style={{ color: "gray" }}
                              className="ts-font-light-16"
                            >
                              N/A
                            </div>
                          )}
                        </Col>
                      </Row>

                      <Row justify={"space-between"}>
                        <Col>
                          <div className="ts-font-regular-16">
                            <img
                              style={{ marginRight: 8 }}
                              src={communicationLogo}
                              alt="Communication Logo"
                            ></img>
                            <>Communication</>
                          </div>
                        </Col>
                        <Col>
                          {resume?.resumeScore ? (
                            <div
                              style={{
                                color: Utils.scoreColorChecker(
                                  resume.resumeScore
                                ),
                              }}
                              className="ts-font-bold-16"
                            >
                              {resume.resumeScore}
                            </div>
                          ) : (
                            <div
                              style={{ color: "gray" }}
                              className="ts-font-light-16"
                            >
                              N/A
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row justify={"space-between"}>
                        <Col>
                          <div className="ts-font-regular-16">
                            <img
                              style={{ marginRight: 8 }}
                              src={commitmentLogo}
                              alt="Commitment Logo"
                            ></img>
                            <>Commitment</>
                          </div>
                        </Col>
                        <Col>
                          {resume?.loyaltyScore ? (
                            <div
                              style={{
                                color: Utils.scoreColorChecker(
                                  resume.loyaltyScore
                                ),
                              }}
                              className="ts-font-bold-16"
                            >
                              {resume.loyaltyScore}
                            </div>
                          ) : (
                            <div
                              style={{ color: "gray" }}
                              className="ts-font-light-16"
                            >
                              N/A
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

              {salary?.predictedMinSalary && isSalaryPredicted && (
                <h5 className="ts-font-bold-20 subheader">
                  Annual Gross Salary Prediction
                </h5>
              )}

              {salary?.predictedMinSalary && isSalaryPredicted && (
                <Table
                  style={{ marginTop: 20, marginBottom: 40 }}
                  columns={salaryColumn}
                  dataSource={salaryData}
                  pagination={false}
                />
              )}

              {salary?.salaryIntervalsPercentages && (
                <div style={section}>
                  <BarChart data={barChartData} />
                </div>
              )}

              {resume?.experiences?.length > 0 && (
                <h5 className="ts-font-bold-20 subheader">Experience</h5>
              )}
              {resume?.experiences?.map((experience, index) => (
                <div style={section} key={index} className="ts-font-regular-16">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="ts-font-bold-20">
                      <b>
                        {experience?.company?.name ? (
                          <a
                            className="url "
                            href={
                              "https://www.linkedin.com/company/" +
                                experience?.company?.linkedinNumericRef ??
                              experience?.company?.linkedinRef
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {experience?.company.name}
                          </a>
                        ) : (
                          experience?.unknownCompanyName
                        )}
                      </b>
                    </div>
                    {experience?.company?.logo ? (
                      <img
                        src={`data:image/png;base64,${experience?.company?.logo}`}
                        alt="company logo"
                        width={70}
                        height={70}
                        style={{ borderRadius: 10 }}
                      />
                    ) : null}
                  </div>
                  {experience?.company?.score && (
                    <div>
                      <b>Score:</b> {experience?.company?.score}
                    </div>
                  )}
                  {experience?.period && experience?.duration && (
                    <div>
                      <b>Period:</b> {experience?.period} (
                      {experience?.duration})
                    </div>
                  )}

                  {experience.position && (
                    <div>
                      <b>Title:</b> {experience?.position}
                    </div>
                  )}
                  {experience?.place && (
                    <div>
                      <b>Location:</b> {experience?.place}
                    </div>
                  )}
                </div>
              ))}
              {resume?.educations?.length > 0 && (
                <h5 className="ts-font-bold-20 subheader">Education</h5>
              )}
              {resume?.educations?.length > 0 &&
                resume?.educations?.map((education, index) => (
                  <div
                    style={section}
                    key={index}
                    className="ts-font-regular-16"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <b>
                        {education?.school?.name ? (
                          <a
                            className="url ts-font-bold-20"
                            href={
                              "https://www.linkedin.com/school/" +
                                education?.school?.linkedinSecondaryRef ??
                              education?.school?.linkedinRef
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {education?.school.name}
                          </a>
                        ) : (
                          education?.unknownSchoolName
                        )}
                      </b>

                      {education?.school?.logo ? (
                        <img
                          src={`data:image/png;base64,${education?.school?.logo}`}
                          alt="company logo"
                          width={70}
                          height={70}
                        />
                      ) : null}
                    </div>
                    {education?.school?.score && (
                      <div>
                        <b>Score:</b> {education?.school?.score}
                      </div>
                    )}
                    {education?.period && (
                      <div>
                        <b>Period:</b> {education?.period}
                      </div>
                    )}
                    {education?.degree && (
                      <div>
                        <b>Program:</b> {education?.degree}
                      </div>
                    )}
                    {education?.degreeType && (
                      <div>
                        <b>Degree:</b> {education?.degreeType}
                      </div>
                    )}
                  </div>
                ))}
              {resume?.cvMainSkillDto?.length > 0 && (
                <h5 className=" ts-font-bold-20 subheader">Skills</h5>
              )}
              {resume?.cvMainSkillDto?.length > 0 && (
                <div style={section}>
                  {resume.cvMainSkillDto.map((skill, index) => (
                    <div key={index} className="ts-font-regular-16">
                      {skill?.name}
                    </div>
                  ))}
                </div>
              )}
              {resume?.certifications?.length > 0 && (
                <h5 className="ts-font-bold-20 subheader">Certifications</h5>
              )}
              {resume?.certifications?.length > 0 && (
                <div style={section}>
                  {resume.certifications.map((certification, index) => (
                    <div key={index} className="ts-font-regular-16">
                      {certification?.name}
                    </div>
                  ))}
                </div>
              )}

              {salary?.predictedMinSalary && isSalaryPredicted && (
                <>
                  <h5 className="ts-font-bold-20 subheader">Salary</h5>
                  <div style={section} className="ts-font-regular-16">
                    <div>
                      <b>Skills :</b> {salary?.skills}
                    </div>
                    <div>
                      <b>Main Skill :</b> {salary?.mainSkills}
                    </div>
                    <div>
                      <b>Working Year :</b> {salary?.workingYear}
                    </div>
                  </div>

                  <div style={section} className="ts-font-regular-16">
                    <div>
                      <b>Assigned Topic No :</b> {salary?.assignedTopicNo}
                    </div>

                    <List
                      className="mt-4 "
                      itemLayout="horizontal"
                      dataSource={salary?.allTopicScores}
                      renderItem={(item) => (
                        <List.Item style={{ padding: "10px 0" }}>
                          <List.Item.Meta
                            className="ts-font-medium-16"
                            title={item.name}
                            description={item.score}
                          />
                        </List.Item>
                      )}
                    />
                    <List
                      className="mt-4"
                      itemLayout="horizontal"
                      dataSource={salary?.allTopicWords}
                      renderItem={(item) => (
                        <List.Item style={{ padding: "10px 0" }}>
                          <List.Item.Meta
                            className="ts-font-medium-16"
                            title={item.name}
                            description={item.featuredWords}
                          />
                        </List.Item>
                      )}
                    />
                  </div>

                  <Table
                    className="mt-5 mb-5"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </>
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: 150 }}
            >
              <Empty />
            </div>
          ))}
      </div>
    </div>
  );
};

const section = {
  // border: "2px dashed #cccccc",
  boxShadow: "0 0px 20px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: "20px",
  margin: "15px 0",
  padding: "20px",
  display: "grid",
  gap: "16px",
  backgroundColor: "white",
};

const sectionHeader = {
  boxShadow: "0 0px 20px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: "20px",
  margin: "15px 0",
  padding: "20px",
  display: "grid",
};

const loadingStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "60vh",
};

export default ResumeSearch;
