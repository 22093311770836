import _ from "lodash";
import utils from "./utils";

let basefolder = "/";
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_PUBLIC_URL) {
  const hostname = utils.getHostNameOfUrl(process.env.REACT_APP_PUBLIC_URL);
  basefolder = _.split(process.env.REACT_APP_PUBLIC_URL, hostname, 2)[1];
}

export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const BASE_PATH = basefolder;
export const TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBnaW5pdGFsZW50LmNvbSIsInN1YnNjcmlwdGlvbnMiOlsiRnJlZSJdLCJzdXJuYW1lIjoiQWRtaW4iLCJyb2xlcyI6W10sIm5hbWUiOiJBZG1pbiIsImV4cCI6MTY2NzEwOTEzMX0.VJnFGWwCXoc4UEcIx9Kj6MP8FJKANuMjTZ38H3uadRp3XYXkC915gVteEpVnI8DYEuQR04Z6kDqq0CmIO-7dfA";
export const BASE_URL = "https://app.talentscore.io/server/api/v1/landing/";
//export const BASE_URL = "https://test-app.talentscore.io/server/api/v1/landing/";
//export const BASE_URL = "http://localhost:8457/api/v1/";
// export const TOKEN = localStorage.getItem("token")
export default {
  API_BASE_URL,
};
