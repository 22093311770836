import { createContext, useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";

export const CommonContext = createContext();

export const CommonProvider = (props) => {
  const [isActive, setActive] = useState(true);
  const [companyDetail, setCompanyDetail] = useState({});
  const [searchData, setSearchData] = useState("");
  const [showedSearchTerm, setShowedSearchTerm] = useState("");
  const [rank, setRank] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const mobileMatched = useMediaQuery("only screen and (max-width: 768px)");

  useEffect(() => {
    if (isActive) {
      document.querySelector("html").classList.remove("active");
    } else {
      document.querySelector("html").classList.add("active");
    }
  }, [isActive]);

  return (
    <CommonContext.Provider
      value={{
        isActive: isActive,
        setActive,
        setCompanyDetail,
        companyDetail: companyDetail,
        mobileMatched: mobileMatched,
        searchData: searchData,
        setSearchData,
        rank: rank,
        setRank,
        isSearch: isSearch,
        setIsSearch,
        showedSearchTerm: showedSearchTerm,
        setShowedSearchTerm,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};
